<template>
  <div class="canvas-container">
    <div id="dimensions-container" :style="{'width': '100%', 'height': `${canvasHeightPercentage}vw`}">
      <canvas @dblclick="onDoubleClick" id="drawer-canvas"></canvas>
    </div>
  </div>
</template>

<script>
import { findProductPlacement } from '../apis/select_drawer_logic';

export default {
  name: 'DrawerVisualizer',
  props: {
    products: Array,
    selected_product: Object,
    draw_rows: Array,
    draw_columns: Array,
    drawer_width: Number,
    drawer_height: Number,
    redraw: Boolean,
    rotate: Boolean,
    position: Boolean,
    drawer_id: Number
  },
  data() {
    return {
      canvas: null,
      element: null,
      dimensions_container: null,
      drawer_rects: [],
      added_product: null,
      is_dragging_product: false,
    };
  },
  watch: {
    products: function () {
      this.drawCanvas();
    },
    selected_compartment: function () {
      this.drawCanvas();
    },
    redraw: function (shouldRedraw) {
      if (shouldRedraw) {
        this.drawCanvas();
        this.$emit('redrawn');
      }
    },
    rotate: function (shouldRotate) {
      console.log('SHOULD ROTATE!!');
      if (shouldRotate) {
        console.log('SHOULD ROTATE!!');
        let isRotated = !this.added_product.isRotated;
        this.$set(this.added_product, 'isRotated', isRotated);

        this.drawCanvas();
        this.$emit('rotated');
      }
    },
    position: function (shouldPosition) {
      if (shouldPosition) {
        let res = findProductPlacement(this.added_product, this.products, this.drawer_id);
        if (res.suitable) {
          this.added_product.x = res.x;
          this.added_product.y = res.y;
          this.added_product.isRotated = res.isRotated;
        }
        this.drawCanvas();
        this.$emit('positioned');
      }
    },
    added_product: function (changedProduct) {
      this.$emit('changed_product', changedProduct);
    }
  },
  mounted() {
    this.added_product = this.selected_product;
    this.element = document.getElementById('drawer-canvas');
    this.dimensions_container = document.getElementById('dimensions-container');
    this.canvas = this.element.getContext('2d');
 
    this.element.width = this.dimensions_container.offsetWidth;
    this.element.height = this.dimensions_container.offsetHeight;

    this.element.addEventListener('touchmove', this.onMouseMove);
    this.element.addEventListener('touchend', this.onMouseUp);
    this.element.addEventListener('touchstart', this.onMouseDown);


    this.drawCanvas();
  },
  beforeDestroy() {
    this.element.removeEventListener('touchmove', this.onMouseMove);
    this.element.removeEventListener('touchend', this.onMouseDown);
    this.element.removeEventListener('touchstart', this.onMouseUp);
  },
  computed: {
    canvasHeightPercentage: function () {
      return (100 * this.drawer_height) / this.drawer_width;
    }
  },
  methods: {
    async drawCanvas() {
      let products = [];
      this.drawer_rects = [];

      if (this.products !== undefined) products = this.products.slice();

      // Sort products by z index
      products.sort((a, b) => {
        if (a.z === undefined) a.z = 0;
        if (b.z === undefined) b.z = 0;
        return a.z - b.z;
      });
      const elementWidth = this.element.width - 6;
      const elementHeight = this.element.height - 6;
      const startAtX = 3;
      const startAtY = 3;

      this.canvas.clearRect(0, 0, this.element.width, this.element.height);
      this.canvas.beginPath();
      this.canvas.rect(startAtX, startAtY, elementWidth, elementHeight);
      this.canvas.strokeStyle = '#5D5E63';
      this.canvas.fillStyle = '#484D55';
      this.canvas.lineWidth = 2;
      this.canvas.font = 'bold 25px Roboto';
      this.canvas.fill();

      // draw the grid
      this.canvas.beginPath();
      this.canvas.rect(2, 2, elementWidth, elementHeight);
      this.canvas.stroke();

      this.canvas.lineWidth = 1;

      const divisionSize = 100; //mm

      const numberOfRows = Math.ceil(this.drawer_height / divisionSize);
      const numberOfColumns = Math.ceil(this.drawer_width / divisionSize);

      for (let i = 0; i < numberOfRows; i++) {
        const row = i * divisionSize;
        this.canvas.lineWidth = 2;
        this.canvas.beginPath();
        this.canvas.moveTo(2, (row * elementHeight) / this.drawer_height);

        this.canvas.strokeStyle = '#5D5E63';
        this.canvas.fillStyle = '#484D55';

        this.canvas.lineTo(elementWidth, (row * elementHeight) / this.drawer_height);
        this.canvas.stroke();
      }

      for (let i = 0; i < numberOfColumns; i++) {
        const column = i * divisionSize;
        this.canvas.lineWidth = 2;
        this.canvas.beginPath();

        this.canvas.strokeStyle = '#5D5E63';
        this.canvas.fillStyle = '#484D55';

        this.canvas.moveTo((column * elementWidth) / this.drawer_width, 2);
        this.canvas.lineTo((column * elementWidth) / this.drawer_width, elementHeight);
        this.canvas.stroke();
      }

      this.canvas.strokeStyle = '#8a909c';
      this.canvas.fillStyle = '#636770';

      this.canvas.lineWidth = 2;

      // draw the boxes
      for (let i = 0; i < products.length; i++) {
        if (products[i].boxType !== undefined) {
          if (products[i].x === undefined) products[i].x = 0;
          if (products[i].y === undefined) products[i].y = 0;

          this.drawProduct(products[i], startAtX, startAtY, elementWidth, elementHeight);
        }
      }


      this.canvas.strokeStyle = '#4B87B3';
      this.canvas.fillStyle = '#496780';

      this.drawProduct(this.added_product, startAtX, startAtY, elementWidth, elementHeight);

      this.$emit('changed_product', this.added_product);
    },
    drawProduct(product, startAtX, startAtY, elementWidth, elementHeight) {
      this.canvas.beginPath();

      const x = startAtX + ((product.x * elementWidth) / this.drawer_width);
      const y = startAtY + ((product.y * elementHeight) / this.drawer_height);
      const width = (product.boxType.width * elementWidth) / this.drawer_width;
      const height = (product.boxType.height * elementHeight) / this.drawer_height;

      this.canvas.rect(x, y, product.isRotated ? height : width, product.isRotated ? width : height);

      this.canvas.fill();
      this.canvas.stroke();

      this.drawer_rects.push({x: x, y: y, w: width, h: height, product: product, compartment: null});
    },
    onMouseUp() {
      this.is_dragging_product = false;
    },
    onMouseDown() {
      this.is_dragging_product = true;
    },
    onMouseMove(event) {
      if (!this.is_dragging_product) return;
      const rect = this.element.getBoundingClientRect();
      let x = event.changedTouches[0].clientX - rect.left;
      let y = event.changedTouches[0].clientY - rect.top; 
 
      const elementWidth = this.element.width - 6;
      const elementHeight = this.element.height - 6;
 
      let productWidth = (this.added_product.isRotated) ? this.added_product.boxType.height : this.added_product.boxType.width;
      let productHeight = (this.added_product.isRotated) ? this.added_product.boxType.width : this.added_product.boxType.height;

      x = ((x * this.drawer_width) / elementWidth) - (productWidth / 2);
      y = ((y * this.drawer_height) / elementHeight) - (productHeight / 2);

      const attractionForce = 50;

      // Calculate contraints
      for (let product of this.products) {
        const pWidth = (product.isRotated) ? product.boxType.height : product.boxType.width;
        const pHeight = (product.isRotated) ? product.boxType.width : product.boxType.height;

        const up = y - (product.y + pHeight);
        const down = product.y - (y + productHeight);
        const left = x - (product.x + pWidth);
        const right = product.x - (x + productWidth);

        const minX = product.x - attractionForce;
        const maxX = product.x +  Math.max(pWidth, productWidth) + attractionForce;

        const minY = product.y - attractionForce;
        const maxY = product.y + Math.max(pHeight, productHeight) + attractionForce;


        if (x > minX && x < maxX) {
          if (up > -attractionForce && up < attractionForce) y = product.y + pHeight;
          if (down > -attractionForce && down < attractionForce) y = product.y - pHeight;
        }

        if (y > minY && y < maxY) {
          if (left > -attractionForce && left < attractionForce) x = product.x + pWidth;
          if (right > -attractionForce && right < attractionForce) x = product.x - pWidth;
        }
      }

      // Drawer limits
      if (x < 0) x = 0;
      if (y < 0) y = 0;
      if (x + productWidth > this.drawer_width) x = this.drawer_width - productWidth;
      if (y + productHeight > this.drawer_height) y = this.drawer_height - productHeight;

      // Snap to drawer limits
      if (x < 0 + attractionForce) x = 0;
      if (x + productWidth > this.drawer_width - attractionForce) x = this.drawer_width - productWidth;
      if (y < 0 + attractionForce) y = 0;
      if (y + productHeight > this.drawer_height - attractionForce) y = this.drawer_height - productHeight;

      // Update added_product position
      this.added_product.x = x;
      this.added_product.y = y;

      this.drawCanvas();
    },
    onDoubleClick() {
      let isRotated = !this.added_product.isRotated;
      this.$set(this.added_product, 'isRotated', isRotated);
      this.drawCanvas();
    }
  },
};
</script>

<style scoped lang="scss">
@import "../scss/globals";

.canvas-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
}

</style>
