<template>
  <div class="container">
    <div class="search-bar">
      <img class="icon blue-filter" src="../assets/scanner.svg" alt="Barcode">
      <input id="eanInput" class="input" type="text"
             @input="$emit('input', $event.target.value)"
             @keyup.enter="$emit('done', value)"
             :value="value"
             :placeholder="text"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScanEANInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    async focusInput() {
      // await new Promise((r) => setTimeout(r, 5000));
      await this.$nextTick();
      // this.$refs.eanInput.focus();
      document.getElementById('eanInput').focus();
      console.log('Focused on input eanInput');
    }
  }
}
</script>

<style scoped lang="scss">
@import "../scss/globals";

.container {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  height: $bar-height;
  box-sizing: border-box;

  .search-bar {
    flex: 1;
    background-color: $primary-color;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    border: 1px solid $border-color;
    min-width: 450px;

    .icon {
      height: $icon-size-medium;
      width: $icon-size-medium;
      margin-left: $default-margin;
    }

    .input {
      background-color: transparent;
      flex: 1;
      color: $text-color;
      border: none;
      margin-left: $half-margin;
      height: -webkit-fill-available;
    }

    .input::placeholder {
      color: $text-darker-color;
    }

  }

  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $primary-color;
    cursor: pointer;
    margin-left: $half-margin;
    aspect-ratio: 1 / 1;

    .icon {
      height: 35px;
      width: 35px;
    }
  }

  .not-clickable {
    background-color: $primary-color !important;
  }

  .button:hover {
    background-color: $hover-color;
  }

}

</style>
