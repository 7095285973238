import Vue from 'vue'
import VueRouter from 'vue-router';
import Notifications from 'vue-notification';

import i18n from './i18n';
import store from './store';

import App from './App.vue'

// Pages
import StartPage from './pages/StartPage.vue';
import DepositPage from './pages/DepositPage.vue';
import SettingsPage from './pages/SettingsPage.vue';

import './scss/fonts.scss';


Vue.config.productionTip = false

Vue.use(VueRouter);
Vue.use(Notifications);

const routes = [
  { path: '/', component: StartPage, name: 'start_page', props: true },
  { path: '/', component: DepositPage, name: 'deposit_page', props: true},
  { path: '/', component: SettingsPage, name: 'settings_page', props: true}
];

const router = new VueRouter({
  routes
});

new Vue({
  i18n,
  router,
  render: h => h(App),
  store
}).$mount('#app');
