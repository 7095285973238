<template>
  <div class="page-container">
    <!-- HEADER -->
    <PageHeader :show_back="true" @back="back" :title="$t('deposit_page.title')"></PageHeader>

    <div class="loading-container" v-if="loadingState != LoadingStates.DONE">
      <p class="text">{{ $t(`deposit_page.loading_states.${loadingState}`, {drawer_name: drawerName}) }}</p>
    </div>

    <div class="page-container" v-if="loadingState == LoadingStates.DONE">

      <!-- TITLE -->
      <div class="buttons-row all-margin-but-bottom">
        <TitleComponent class="go-flex" :title="$t('deposit_page.title_cdi', {cdi: ean})"></TitleComponent>
        <TitleComponent class="go-flex left-margin" :title="$t('deposit_page.title_code', {code: product.productType.code})"></TitleComponent>
      </div>

      <!-- DRAWER VIEW -->
      <div class="drawer-view-container">
        <p class="title">{{ $t('deposit_page.drawer', {drawer_name: drawerName}) }}</p>
        <DrawerView :drawer_id="drawer.id" @rotated="rotate = false" :rotate="rotate" :position="position" @positioned="position = false" class="view" @changed_product="changed_product = $event" :redraw="redraw_drawer" @redrawn="redraw_drawer = false" :drawer_width="3427" :drawer_height="810" :selected_product="product" :products="drawer.products"></DrawerView>
        <p class="subtitle">{{$t('deposit_page.drawer_helper')}}</p>
      </div>

      <div class="buttons-row all-margin-but-bottom">
        <BigButton class="go-flex" @click="rotate = true" :text="$t('deposit_page.rotate_box')" icon="refresh.svg" filter="green-filter"></BigButton>
        <BigButton class="left-margin go-flex" @click="position = true" :text="$t('deposit_page.suggested_position')" icon="suggested-position.svg" filter="blue-filter"></BigButton>
        <BigButton class="left-margin go-flex" @click="discardDrawer" :text="$t('deposit_page.change_drawer')" icon="swap.svg" filter="orange-filter"></BigButton>
      </div>

      <div class="buttons-row all-margin">

        <SelectComponent
          style="flex: 1"
          :text="$t('deposit_page.select_box')"
          :options="boxOptions"
          v-model="selectedBoxType"
        ></SelectComponent>

        <QuantityInput
          class="left-margin"
          :text="$t('deposit_page.quantity')"
          v-model="product_quantity"

        ></QuantityInput>

        <!-- <BigButton class="left-margin" @click="done" :text="$t('deposit_page.change_drawer')" icon="refresh.svg" filter="orange-filter"></BigButton> -->

      </div>

      <div class="spacer"></div>

      <div class="buttons-row left-margin">
        <ScanEANInput :text="$t('deposit_page.scan_new_code')" style="flex: 1" v-model="newEan" @done="onScanNewEAN"></ScanEANInput>

        <BigButton class="left-margin" @click="storeDrawer" :text="$t('deposit_page.store')" icon="cancel.svg" filter="red-filter"></BigButton>
        <BigButton class="all-margin" @click="done" :text="$t('deposit_page.done')" icon="done.svg" filter="green-filter"></BigButton>
      </div>
    </div>

    
  </div>
</template>

<script>
import PageHeader from '../components/PageHeader.vue';
import DrawerView from '../components/DrawerView.vue';
import { getCDI } from '../apis/sql_bridge';
import { GetBoxTypeFromID, GetBoxTypes, GetDrawerFromID, GetProductTypeFromCode, RequestDrawerToBay, CreateProduct, ExecuteQuery, StoreDrawerFromBay } from '@/apis/ono_requests';
import { selectDrawer } from '@/apis/select_drawer_logic';
import SelectComponent from '@/components/SelectComponent.vue';
import BigButton from '@/components/BigButton.vue';
import ScanEANInput from '@/components/ScanEANInput.vue';
import QuantityInput from '@/components/QuantityInput.vue';
import TitleComponent from '@/components/TitleComponent.vue';
export default {
  name: 'DepositPage',
  components: {
    PageHeader,
    DrawerView,
    SelectComponent,
    BigButton,
    ScanEANInput,
    QuantityInput,
    TitleComponent
  },
  data() {
    return {
      ean: this.$attrs.ean,
      drawer: null,
      product_quantity: "",
      LoadingStates: {
        FETCHING_CDI_INFO: 'fetching_cdi_info',
        FINDING_SUITABLE_DRAWER: 'finding_suitable_drawer',
        BRINGING_DRAWER_TO_BAY: 'bringing_drawer_to_bay',
        DONE: 'done',
        STORING_DRAWER: 'storing_drawer',
        CREATING_PRODUCT: 'creating_product'
      },
      changed_product: null,
      boxTypes: [],
      selectedBoxType: '1',
      loadingState: 'fetching_cdi_info',
      newEan: '',
      product: null,
      redraw_drawer: false,
      rotate: false,
      position: false,
      discarded_drawer_ids: [],
    };
  },
  watch: {
    selectedBoxType: function(newBoxType) {
      if (this.product != null) {
        let id = parseInt(newBoxType);
        let boxType = this.boxTypes.filter((bt) => bt.id == id)[0];

        this.$set(this.product, 'boxTypeID', id);
        this.$set(this.product, 'boxType', boxType);
        this.redraw_drawer = true;
      }
    }
  },
  computed: {
    drawerName: function() {
      if (this.drawer == null) return '';
      return this.drawer.name;
    },
    boxOptions: function() {
      let options = [];
      for (let box of this.boxTypes) {
        options.push({
          value: box.id,
          text: box.name
        });
      }

      return options;
    },
  },
  methods: {
    back() {
      this.$router.push({name: 'start_page'});
    },
    async start() {
      this.loadingState = this.LoadingStates.FETCHING_CDI_INFO;

      // Fetch all box types
      this.boxTypes = await GetBoxTypes();

      if (this.ean == '') {
        this.$router.push({name: 'start_page'});
      }

      // Read the EAN code and fetch data from ERP
      let cdi;
      try {
        cdi = await getCDI(this.ean);

        if (cdi == null) {
          this.$notify({
            group: 'main',
            type: 'error',
            title: this.$t('notification.error_getting_cdi'),
            text: this.$t('notification.error_getting_cdi_description', {ean: this.ean})
          });
          this.$router.push({name: 'start_page'});
          return;
        }
      }catch (e) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: this.$t('notification.error_getting_cdi'),
          text: `${e}`
        });
        this.$router.push({name: 'start_page'});
        return;
      }

      if (cdi == null) return;

      // Change loading status to next phase
      this.loadingState = this.LoadingStates.FINDING_SUITABLE_DRAWER;

      // Now that we got the CDI, we should find it's product type
      let productType;

      try {
        let ptRes = await GetProductTypeFromCode(cdi.code);
        if (ptRes.data !== null && ptRes.data.productType !== null) {
          productType = ptRes.data.productType;
        }
      }catch(e) {
        console.log(e);
      }

      console.log(JSON.stringify(productType));
      
      if (productType == null) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: this.$t('notification.product_type_not_found'),
          text: this.$t('notification.product_type_not_found_description', {code: cdi.code})
        });
        this.$router.push({name: 'start_page'});
        return;
      }

      // Check if product is already inside the system
      try {
        let productRows = await ExecuteQuery(`SELECT id FROM product WHERE code = '${cdi.cdi}' and valid`);
        if (productRows !== undefined && productRows !== null && productRows.length > 0) {
          this.$notify({
            group: 'main',
            type: 'error',
            title: this.$t('notification.product_already_exists'),
            text: this.$t('notification.product_already_exists_description', {cdi: cdi.cdi})
          });
          this.$router.push({name: 'start_page'});
          return;
        }
      }catch (e) {
        console.log(e);
        return;
      }

      if (cdi.mag != 'MBTRN') {
        this.$notify({
          group: 'main',
          type: 'error',
          title: this.$t('notification.wrong_mag'),
          text: this.$t('notification.wrong_mag_description', {cdi: cdi.cdi})
        });
        this.$router.push({name: 'start_page'});
        return;
      }

      // Let's create the new product!
      let status = 0;
      if (cdi.bachBlock == 1 || cdi.cdiBlock == 1) status = 1;
      let productMeta = {
        Status: status,
        CDI: cdi.cdi,
        Versione: cdi.version,
        Lotto: cdi.batch,
        'Lotto bloccato': cdi.bachBlock,
        'CDI bloccato': cdi.cdiBlock,
        'Data lotto': cdi.batchDate,
        Scadenza: cdi.expirationDate,
        Esponente: cdi.exponent,
        Seriale: cdi.serial,
        Riservati: cdi.bookedQuantity,
        'Data deposito': new Date(Date.now()).toISOString(),
        Magazzino: cdi.mag,
        'CDI origine': cdi.splittedCDI
      };

      this.product_quantity = `${cdi.quantity}`;

      let product = {
        code: cdi.cdi,
        quantity: cdi.quantity,
        productTypeID: productType.id,
        productType: productType,
        boxType: productType.defaultBoxType,
        boxTypeID: productType.defaultBoxTypeID,
        meta: JSON.stringify(productMeta),
      };

      // Check if product type has the defualt box type
      if (product.boxTypeID == 0) {
        // Get first box type
        let boxType = await GetBoxTypeFromID(this.$store.state.settings.default_box_type_id);
        product.boxTypeID = boxType.id;
        product.boxType = boxType;
      }

      console.log('DONE!');
      await this.findSuitableDrawer(product);
    },
    async discardDrawer() {
      this.discarded_drawer_ids.push(this.drawer.id);
      await this.findSuitableDrawer(this.product);
    },
    async findSuitableDrawer(product) {
      this.loadingState = this.LoadingStates.FINDING_SUITABLE_DRAWER;
      // Great! Now let's find a suitable drawer
      let drawerRes = await selectDrawer(product, this.discarded_drawer_ids);
      if (drawerRes.isNotOptimal != null) {
        this.$notify({
          group: 'main',
          type: 'warn',
          title: this.$t('notification.drawer_not_optimal'),
          text: this.$t('notification.drawer_not_optimal_description')
        });
      }
      if (drawerRes.id == null) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: this.$t('notification.error_finding_drawer'),
          text: this.$t('notification.error_finding_drawer_description')
        });
        this.$router.push({name: 'start_page'});
        return;
      }

      product.x = drawerRes.x;
      product.y = drawerRes.y;
      product.isRotated = drawerRes.isRotated;
      this.product = product;

      // Get the drawer object
      this.drawer = await GetDrawerFromID(drawerRes.id);

      // Change loading status to next phase
      this.loadingState = this.LoadingStates.BRINGING_DRAWER_TO_BAY;

      // Request drawer to bay
      await RequestDrawerToBay(this.drawer.id);

      // Drawer is in bay! Done!
      this.loadingState = this.LoadingStates.DONE;
    },
    async done() {
      this.loadingState = this.LoadingStates.CREATING_PRODUCT;
      
      let res = await CreateProduct(this.changed_product);

      if (res === true) {
        this.$notify({
          group: 'main',
          type: 'success',
          title: this.$t('notification.done_creating_product'),
          text: this.$t('notification.done_creating_product_description')
        });
        this.$router.push({name: 'start_page'});
      }else {
        this.$notify({
          group: 'main',
          type: 'error',
          title: this.$t('notification.error_creating_product'),
          text: `${res}`
        });
        this.loadingState = this.LoadingStates.DONE;
      }

      

      return;

    },
    async onScanNewEAN() {
      await this.done();
      this.$router.push({name: 'deposit_page', params: {
        ean: this.newEan
      }});
    },
    async storeDrawer() {
      this.loadingState = this.LoadingStates.STORING_DRAWER;

      try {
        await StoreDrawerFromBay();
      } catch (e) {
        console.log(e);
      }

      this.$router.push({name: 'start_page'});
    }
  },
  mounted() {

    this.start();
  }
};

</script>

<style scoped lang="scss">
@import "../scss/globals.scss";

.page-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  overflow: hidden;

  .loading-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .text {
      color: $text-color;
    }
  }

  .grid-viewers {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: $default-margin;
    column-gap: $default-margin;
    row-gap: $default-margin;
  }

  .drawer-view-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin-top: $default-margin;
    margin-left: $default-margin;
    margin-right: $default-margin;
    padding: $default-margin;
    background-color: $primary-color;
    border: 1px solid $border-color;

    .title {
      margin: 0;
      margin-bottom: $default-margin;
      color: $text-color;
      font-weight: bold;
      align-self: center;
    }

    .subtitle {
      margin: 0;
      margin-top: $half-margin;
      margin-left: $default-margin;
      margin-right: $default-margin;
      text-align: center;
      color: $text-darker-color;

    }
  }

  .buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
  }

  .spacer {
    flex: 1;
    width: 100%;
  }

  .view-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .left-margin {
    margin-left: $default-margin;
  }

  .all-margin {
    margin: $default-margin;
  }

  .all-margin-but-bottom {
    margin: $default-margin;
    margin-bottom: 0;
  }

  .left-right-margin {
    margin-left: $default-margin;
    margin-right: $default-margin;
  }

  .go-flex {
    flex: 1;
  }

}
</style>