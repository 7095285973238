import Vue from 'vue';
import VueI18n from 'vue-i18n';

import store from '../store/index';

const messages = {
  en: require('../locales/en.json'),
  it: require('../locales/it.json')
};

Vue.use(VueI18n);

export default new VueI18n({
  locale: store.state.settings.locale,
  fallbackLocale: 'it',
  messages
});
