<template>
  <div id="app" class="body-container">
    <router-view></router-view>
    <notifications group="main" position="bottom right">
      <template slot="body" slot-scope="props">
        <div class="notification-body">
          <p class="title" :class="{ 'title-green': props.item.type === 'success', 'title-red': props.item.type === 'error', 'title-blue': props.item.type === 'info', 'title-orange': props.item.type === 'warn'  }" >{{props.item.title}}</p>
          <div v-if="props.item.text !== undefined" class="text" v-html="props.item.text"></div>
        </div>
      </template>
    </notifications>
  </div>
</template>
<script>

export default {
  name: 'App',
  components: {
  }
}
</script>
<style lang="scss">
@import "scss/globals";

body {
  background-color: $background-color;
  margin: 0;
}

body, p, h1, div, input, select, option, textarea, th, tr {
  font-family: 'Roboto', serif;
  font-size: $font-size-medium;
  user-select: none;
}

.body-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}


*:focus {
  outline: none;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background: transparent;
  cursor: pointer;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Selection color */
::selection {
  background: $selected-color;
}

::-moz-selection {
  background: $selected-color;
}

.notification-body {
  background-color: $primary-color;
  border: 1px solid $border-color;
  padding: $default-margin;
  box-sizing: border-box;
  margin-top: $half-margin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  .title {
    font-weight: bold;
    padding: 0;
    margin: 0 0 $half-margin;
  }

  .title-green {
    color: $default-green-color;
  }

  .title-red {
    color: $default-red-color;
  }

  .title-blue {
    color: $default-blue-color;
  }

  .title-orange {
    color: $default-orange-color;
  }

  .text {
    color: $text-color;
    margin: 0;
    padding: 0;
    width: 100%;
    word-wrap: break-word;
  }
}

.vue-notification-group {
  bottom: 5px !important;
  right: 5px !important;
}
</style>
