<template>
  <div class="page-container">
    <!-- HEADER -->
    <PageHeader :show_back="true" @back="back" :title="$t('settings_page.title')"></PageHeader>

    <div style="padding: 10px" class="page-container">
      <QuantityInput
        class="left-margin"
        :text="$t('settings_page.bay_id')"
        :value="`${bay_id}`"
        @input="onBayIDChanged($event)"
      ></QuantityInput>
    </div>

    


  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import PageHeader from '../components/PageHeader.vue';
import QuantityInput from '../components/QuantityInput.vue';
export default {
  name: 'SettingsPage',
  components: {
    PageHeader,
    QuantityInput
  },
  data() {
    return {
    }
  },
  computed: mapState({
    bay_id: state => state.settings.bay_id
  }),
  methods: {
    ...mapMutations('settings', [
      'setBayID'
    ]),
    onBayIDChanged(newBayID) {
      this.setBayID(parseInt(`${newBayID}`))
    },
    back() {
      this.$router.push({name: 'start_page'});
    },
  }
}

</script>

<style scoped lang="scss">
@import "../scss/globals.scss";

.page-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  overflow: hidden;
}

</style>
