<template>
  <div class="page-container">
    <PageHeader :show_settings="true" @settings="goToSettings" :title="$t('start_page.title')"></PageHeader>

    <div class="center-container">
      <img style="padding-bottom: 50px" class="icon blue-filter big-icon"  src="../assets/barcode-scanner.svg" alt="Scanner">
      <p class="description">{{$t('start_page.description')}}</p>
      <ScanEANInput  :text="$t('start_page.scan_ean')"  v-model="ean" @done="onDone"></ScanEANInput>

      <div class="buttons-row left-margin">
        <BigButton class="go-flex" style="margin-right: 20px" @click="onDone" :text="$t('start_page.confirm')" icon="done.svg" filter="green-filter"></BigButton>
        <BigButton class="left-margin" @click="storeDrawer" :text="$t('deposit_page.store')" icon="cancel.svg" filter="red-filter"></BigButton>
      </div>
      

    </div>
    

  </div>
</template>

<script>
import ScanEANInput from '@/components/ScanEANInput.vue';
import PageHeader from '../components/PageHeader.vue';
import BigButton from '@/components/BigButton.vue';

import { StoreDrawerFromBay } from '@/apis/ono_requests';

export default {
  name: 'StartPage',
  components: {
    PageHeader,
    ScanEANInput,
    BigButton
  },
  data() {
    return {
      ean: ""
    };
  },
  methods: {
    onDone() {
      this.$router.push({name: 'deposit_page', params: {
        ean: this.ean
      }});
    },
    goToSettings() {
      this.$router.push({name: 'settings_page'});
    },
    async storeDrawer() {
      try {
        let res = await StoreDrawerFromBay();
        if (!res) {
          this.$notify({
            group: 'main',
            type: 'error',
            title: this.$t('notification.no_drawer_in_bay'),
            text: this.$t('notification.no_drawer_in_bay_description')
        });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../scss/globals";

.page-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  overflow: hidden;

  .big-icon {
    width: 200px;
    height: 200px;
  }

  .center-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .description {
    color: $default-blue-color;
    font-weight: bold;
    margin: 0;
    margin-bottom: 50px;
    margin-top: 20px
  }

  .buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    margin-top: 50px;
  }

}

</style>