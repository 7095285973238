<template>
  <div class="select-container">

    <p class="title">{{text}}</p>

    <select class="select-input" :value="value" @input="$emit('input', $event.target.value)">
      <option v-for="option in options" :key="option.value" :value="option.value" >{{option.text}}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'SelectComponent',
  props: {
    text: {
      type: String,
    },
    value: {
      type: String
    },
    options: {
      type: Array
    }
  }
}
</script>

<style scoped lang="scss">
@import "../scss/globals";

.select-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: $half-margin;
  height: $bar-height;
  background-color: $primary-color;
  border: 1px solid $border-color;
  box-sizing: border-box;

  .title {
    color: $text-color;
    font-size: $font-size-medium;
    margin: 10px;
    padding: 0;
    flex: 1;
  }

  .select-input {
    background-color: transparent;
    color: $default-blue-color;
    font-weight: bold;
    border: none;
    margin-left: $half-margin;
    height: -webkit-fill-available;
  }

  .input::placeholder {
    color: $text-darker-color;
  }

}

</style>
