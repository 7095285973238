<template>
  <div class="header">
    
    <div @click="$emit('back')" v-if="show_back" class="button">
      <img class="icon white-filter" src="../assets/arrow_back.svg" alt="Back">
    </div>

    <div @click="$emit('settings')" v-if="show_settings" class="button">
      <img class="icon white-filter" src="../assets/settings.svg" alt="Settings">
    </div>

    <div class="title"><p>{{ title }}</p></div> 
    
    <div @click="$emit('next')" v-if="show_next" class="button">
      <img class="icon white-filter" src="../assets/arrow_forward.svg" alt="Next">
    </div>

    <div @click="$emit('done')" v-if="show_done" class="button">
      <img class="icon green-filter" src="../assets/done.svg" alt="Next">
    </div>

  </div>
</template>

<script>

export default {
  name: 'PageHeader',
  props: {
    title: String,
    show_back: {
      type: Boolean,
      default: false
    },
    show_next: {
      type: Boolean,
      default: false
    },
    show_done: {
      type: Boolean,
      default: false
    },
    show_settings: {
      type: Boolean,
      default: false
    }
  }
}

</script>

<style scoped lang="scss">
@import "../scss/globals.scss";

.header {
  background-color: $background-container-color;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  height: $header-size;
  border-bottom: 1px solid $border-color;


  .button {
    background-color: $background-container-color;
    height: $header-size;
    width: $header-size;
    padding-left: $default-margin;
    padding-right: $default-margin;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon {
      height: $icon-size-big;
      width: $icon-size-big;
    }

  }

  .title {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
      color: $text-color;
      font-weight: bold;
      font-size: $font-size-big;
    }
  }


}

</style>
