const state = () => ({
  locale: 'it',
  ono_server_ip: '64.226.104.129',
  ono_server_port: 7576,
  ono_server_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6LTEsImlzcyI6Im9ub1NlcnZlciIsInN1YiI6InNvbWVvbmUiLCJleHAiOjE2MjA4MTA4MTYsIm5iZiI6MTYyMDcyNjIxNiwiaWF0IjoxNjIwNzI0NDE2LCJqdGkiOiJvbm8tc2VydmVyIn0.A4Oz2_dzlDz9eA0Z2HI3apx2OgibfeQ6IuAnFwpQTIA',
  bay_id: 1,
  erp_bridge: {
    server: "64.226.104.129",
    port: 7590,
  },
  drawer_width: 3427,
  drawer_height: 810,
  default_box_type_id: 1,
});

const getters = {
};

const actions = {
};

const mutations = {
  setBayID(state, bayID) {
    state.bay_id = bayID;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
