<template>
  <div class="button" :class="{'selected': selected}" @click="$emit('click')">
    <p class="title">{{text}}</p>
    <img class="icon" :class="filter" :src="getUrl(icon)" :alt="icon">
  </div>
</template>

<script>
export default {
  name: 'BigButton',
  props: {
    text: {
      type: String,
      default: 'Button'
    },
    icon: {
      type: String,
      default: 'update.svg'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filter: {
      type: String,
      default: 'white-filter'
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getUrl(icon_name) {
      return require(`../assets/${icon_name}`);
    }
  }
}
</script>

<style scoped lang="scss">
@import "../scss/globals";

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: $half-margin;
  cursor: pointer;
  height: $bar-height;
  background-color: $primary-color;
  border: 1px solid $border-color;
  box-sizing: border-box;

  .title {
    color: $text-color;
    font-size: $font-size-medium;
    font-weight: bold;
    margin: 10px;
    padding: 0;
  }

  .icon {
    width: $icon-size-big;
    height: $icon-size-big;
    margin-right: $half-margin;
  }
}

.selected {
  background-color: $brighter-hover-color;
}

</style>
