import Vue from 'vue';
import Vuex from 'vuex';

import persist from './persist';

// Modules
import settings from './modules/settings';
import general from './modules/general';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    settings,
    general
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins: [persist.plugin]
});
