import axios from 'axios';
import store from '../store';

export async function MakeONORequest(name, data) {
  return axios.post(`http://${store.state.settings.ono_server_ip}:${store.state.settings.ono_server_port}/grpc/${name}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store.state.settings.ono_server_token}`
    }
  });
}

export async function GetProductsOfDrawer(drawerID) {
  console.log(` Drawer id: ${drawerID}` );
  const res = await MakeONORequest('GetDrawerProducts', {
    drawerID: drawerID,
    includeProductsInCompartments: false
  });
  
  return res.data.products;
}

export async function GetDrawerFromID(drawerID) {
  console.log(`Getting drawer from ID: ${drawerID}` );
  const res = await MakeONORequest('GetDrawerFromID', {
    id: drawerID
  });

  return res.data.drawer;
}

export async function GetProductTypeFromCode(code) {
  console.log(`Getting product type from code: ${code}`);
  return MakeONORequest('GetProductTypeFromCode', {
    code: code
  });
}

export async function GetBoxTypeFromID(id) {
  console.log(`Getting boxType from ID: ${id}`);
  const res = await MakeONORequest('GetBoxTypeFromID', {
    id: id
  });
  return res.data.boxType;
}

export async function ExecuteQuery(query) {
  console.log(`Executing query in ONO db: ${query}`);
  const res = await MakeONORequest('ExecuteQuery', {
    query: query
  });
  return res.data.jsonRows;
}

export async function GetBoxTypes() {
  const res = await MakeONORequest('GetBoxTypes', {});
  return res.data.boxTypes;
}

export async function RequestDrawerToBay(drawerID) {
  try {

    // Check if drawer is already in bay
    const bayRes = await MakeONORequest('GetBayDrawer', {
      bayID: store.state.settings.bay_id
    });
    let bayDrawer = bayRes.data.drawer;

    if (bayDrawer && bayDrawer.id === drawerID) return true;

    await MakeONORequest('RequestDrawer', {
      drawerID: drawerID,
      bayID: store.state.settings.bay_id
    });
    return true;
  }catch (e) {
    console.log(e);
    return false;
  }
}

export async function StoreDrawerFromBay() {
  try {
    await MakeONORequest('StoreDrawer', {
      bayID: store.state.settings.bay_id
    });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function CreateProduct(product) {
  try {
    await MakeONORequest('CreateProduct', {
      product: product,
      pluginPayload: '{"dontShowPopup": true}',
      qchangeMeta: '{}',
      bayID: store.state.settings.bay_id
    });
    return true;
  }catch (e) {
    console.log(e);
    return e;
  }
}