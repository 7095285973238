import store from '../store';
import axios from 'axios';
import { escape } from 'sqlstring';


export async function ExecuteERPQuery(query) {
  return axios.post(`http://${store.state.settings.erp_bridge.server}:${store.state.settings.erp_bridge.port}/query`, 
    {
      query: query
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: 60000
    }
  );
}


export async function getCDI(eanCode) {
  console.log('Sending execute ERP query');

  let rows = await ExecuteERPQuery(`
    SELECT mag, cdi, splittedCDI, code, version, exponent, quantity, batch, bachBlock, cdiBlock, batchDate, expirationDate, serial, bookedQuantity
    FROM [ONOLogistics].[dbo].[vw_ONO_cdi_availability] WHERE cdi = ${escape(eanCode)};
  `);
 
  console.log('ERP query response');
  console.log(rows);

  rows = rows.data.recordsets[0];
  
  console.log(rows);
  console.log(` --- ROWS: ${rows.length}`);

  if (rows.length > 0) {
    return rows[0];
  }

  return;
}
