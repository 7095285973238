<template>
  <div class="container">

    <p class="title">{{text}}</p>
    <input autofocus class="input" type="number"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="$emit('done', value)"
      :value="value"
    >
  </div>
</template>

<script>
export default {
  name: 'QuantityInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
@import "../scss/globals";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: $half-margin;
  height: $bar-height;
  background-color: $primary-color;
  border: 1px solid $border-color;
  box-sizing: border-box;

  .title {
    color: $text-color;
    font-size: $font-size-medium;
    margin: 10px;
    padding: 0;
    flex: 1;
    margin-right: 20px;
  }

  .input {
    background-color: transparent;
    color: $default-blue-color;
    border: none;
    font-weight: bold;
    margin-left: $half-margin;
    height: -webkit-fill-available;
    max-width: 75px;
  }

  .input::placeholder {
    color: $text-darker-color;
  }


}

</style>
