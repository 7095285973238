import { MakeONORequest, GetDrawerFromID, GetProductsOfDrawer } from "./ono_requests";
import store from '../store';


/*
  This function returns the selected drawerID (or null if no drawer found)
*/
export async function selectDrawer(product, discardedDrawerIDs) {

  // Extract product type meta
  let ptMeta = {};

  try {
    ptMeta = JSON.parse(product.productType.meta);
  }catch (e) {
    ptMeta = {};
  }

  // Query all drawers
  const drawersRes = await MakeONORequest('ExecuteQuery', {
    query: `
      SELECT
          d.id,
          d.meta,
          COALESCE(pc.count, 0) as product_count,
          (COALESCE(pc.occupied_area,0) + COALESCE(comp.occupied_area, 0)) as occupied_area,
          (3427 * 810) - (COALESCE(pc.occupied_area, 0) + COALESCE(comp.occupied_area, 0)) as free_area,
          s.id as slot_id, s.bay_id as bay_id
      FROM drawer d
      LEFT JOIN slot s ON d.slot_id = s.id
      LEFT JOIN (
          SELECT drawer_id, COUNT(*) as count, SUM(bt.width * bt.height) as occupied_area
          FROM product LEFT JOIN box_type bt on product.box_type_id = bt.id
          WHERE product.valid
          GROUP BY drawer_id
      ) as pc ON d.id = pc.drawer_id
      LEFT JOIN (
          SELECT drawer_id, COUNT(*) as count, SUM(b.width * b.height) as occupied_area
          FROM compartment LEFT JOIN box_type b on compartment.box_type_id = b.id
          WHERE compartment.valid
          GROUP BY drawer_id
      ) as comp ON d.id = comp.drawer_id
      WHERE pc.count < 44
    `
  });
  const data = drawersRes.data.jsonRows;

  let drawers = [];

  // Parse all the drawers
  for (let d of data) {
    let drawer = JSON.parse(d);
    try {
      drawer.meta = JSON.parse(drawer.meta);
    }catch (e){
      drawer.meta = {};
    }

    if (drawer.meta == null) drawer.meta = {};

    drawers.push(drawer);
  }

  console.log('DRAWERS:');
  console.log(JSON.stringify(drawers));

  // We have all the data we need to take a decision! 

  // First of all, search a drawer that has the same "Approvvigionamento" and "Linea Preferenziale"
  let validDrawers = drawers.filter((d) => d.meta['Approvvigionamento'] == ptMeta['Approvvigionamento'] && d.meta['Linea Preferenziale'] == ptMeta['Linea Preferenziale']);

  if (validDrawers.length > 0) {
    // We should try to use the drawer that's already in bay, if there's one
    let bayDrawer = validDrawers.filter((d) => d.bay_id == store.state.settings.bay_id);
    if (bayDrawer.length > 0) {
      let res = await isDrawerSuitable(product, bayDrawer[0].id, discardedDrawerIDs);
      if (res.suitable) return res;
    }
    

    // Find the fullest valid drawer and check if there's enough space for the product
    validDrawers.sort((a, b) => a.free_area - b.free_area);

    // Find the first drawer with enough space to fit the product
    for (let d of validDrawers) {
      let res = await isDrawerSuitable(product, d.id, discardedDrawerIDs);
      if (res.suitable) {
        return res;
      }
    }
    
    // None of the valid drawer have enough space... Proceed to next step 
  }

  // Hmm no drawer with the same meta found.. We should use an empty one!
  validDrawers = drawers.filter((d) => d.product_count == 0);

  if (validDrawers.length > 0) {
    // We should try to use the drawer that's already in bay, if there's one
    let bayDrawer = validDrawers.filter((d) => d.bay_id == store.state.settings.bay_id);
    if (bayDrawer.length > 0) {
      let res = await isDrawerSuitable(product, bayDrawer[0].id, discardedDrawerIDs);
      if (res.suitable) return res;
    }
    

    // Just find one randomly cause they're all empty...
    for (let d of validDrawers) {
      let res = await isDrawerSuitable(product, d.id, discardedDrawerIDs);
      if (res.suitable) return res;
    }

  }

  // We couldn't find a suitable drawer... Let's just try to put it in the most empty one
  drawers.sort((a, b) => a.free_area - b.free_area);

  for (let d of drawers) {
    let res = await isDrawerSuitable(product, d.id, discardedDrawerIDs);
    if (res.suitable) {
      res.isNotOptimal = true;
      return res;
    }
  }

  // Wow everything seems to be full! We should show an error
  return {id: null};
}

/**
 * This function checks if drawer is available and if product can fit inside the drawer
 * 
 * @param {*} product 
 * @param {*} drawerID 
 * @returns 
 */
export async function isDrawerSuitable(product, drawerID, discardedDrawerIDs) {
  console.log(`DISCARDED DRAWERS : ${discardedDrawerIDs} DRAWER ID: ${drawerID}`);
  if (discardedDrawerIDs.includes(drawerID)) return false;
  const available = await isDrawerAvailiable(drawerID);
  console.log(`DRAWER ID: ${drawerID} IS AVAILABLE : ${available}`);
  if (!available) return false;


  // Make sure to deepcopy the element so we don't accidentally modify the original data
  product = JSON.parse(JSON.stringify(product));

  // Get all the products of the drawer
  let drawerProducts = await GetProductsOfDrawer(drawerID);

  console.log(`DRAWER ID: ${drawerID} DRAWER PRODUCTS: ${drawerProducts.length}`);

  return findProductPlacement(product, drawerProducts, drawerID);
}

export function findProductPlacement(product, drawerProducts, drawerID) {
  // Try to fit the box inside the drawer using brute force approach
  for (let rotated of [false, true]) {
    for (let y = 0; y < store.state.settings.drawer_height - product.boxType.height; y += 10) {
      for (let x = 0; x < store.state.settings.drawer_width - product.boxType.width; x += 10) {
        product.x = x;
        product.y = y;
        
        if (rotated) {
          product.isRotated = true;
          let width = product.boxType.width;
          product.boxType.width = product.boxType.height;
          product.boxType.height = width;
        }

        if (isProductInsideDrawer(product) && !isProductOverlapping(product, drawerProducts)) {
          return {suitable: true, x: product.x, y: product.y, isRotated: product.isRotated, id: drawerID};
        }
      }
    }
  }

  return {suitable: false, id: null};
}

function isProductInsideDrawer(p) {
  if (p.x < 0 || p.x + p.boxType.width > store.state.settings.drawer_width) return false;
  if (p.y < 0 || p.y + p.boxType.height > store.state.settings.drawer_height) return false;

  return true;
}

function isProductOverlapping(p, products) {
  for (let o of products) {
    if (
      (Math.max(p.x, o.x) - Math.min(p.x + p.boxType.width, o.x + o.boxType.width) < 0) &&
      (Math.max(p.y, o.y) - Math.min(p.y + p.boxType.height, o.y + o.boxType.height) < 0)
    ) {
      return true;
    }
  }

  return false;
}

/**
 * This function checks the availability of a drawer given it's id.
 * A drawer is not available when:
 *  - It's being moved
 *  - The meta inUse is set to TRUE
 *  - It's in another bay
 * 
 */
async function isDrawerAvailiable(drawerID) {
  let drawer = await GetDrawerFromID(drawerID);

  let meta = {};

  try {
    meta = JSON.parse(drawer.meta);
  }catch (e) {
    meta = {};
  }

  if (meta == null) meta = {};

  console.log(`DRAWER OBJ: ${JSON.stringify(drawer)}`);
  console.log(`DRAWER META: ${JSON.stringify(meta)}`);
  console.log(`meta.inUse === true: ${meta.inUse === true}`);
  console.log(`drawer.slotID === null: ${drawer.slotID === null}`);
  console.log(`drawer.slot.bayID !== store.state.settings.bay_id: ${drawer.slot.bayID !== store.state.settings.bay_id}`);
  console.log(`drawer.slot.bayID = ${drawer.slot.bayID }`);

  if (meta.inUse === true) return false;
  if (drawer.slotID === null) return false;
  if (drawer.slot.bayID != null && drawer.slot.bayID != 0 && drawer.slot.bayID !== store.state.settings.bay_id) return false;

  return true;
}
